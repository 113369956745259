<script>
export default {
  components: {

  },
  props: {
    user: Object
  },
  data() {
    return {
      search: {

      },
      sort: { sort: 1 },
      category: '',
      list: [],
      vendors: [],
      limit: 1000,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1 // 현재 페이지
    }
  },
  created() {

  },
  mounted() {
    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    searchByPage: function(page) {
      this.page = page
      this.searchList().then()
    },
    searchList: async function() {
      const loader = this.$loading.show()
      try {
        const params = {
          search: {},
          sort: this.sort,
          limit: this.limit,
          page: this.page,
          parent: this.user ? this.user.id : null
        }

        this.list = []
        const result = await this.$API.setting.vendors(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.vendors = []
        for(const key in result.documents) {
          const doc = result.documents[key]
          doc.active = doc.active === 1
          doc.maintenance = doc.maintenance === 1
          doc.useLobby = doc.useLobby === 1
          this.vendors.push(doc)
        }

        this.reload()
        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
        this.page = result.page
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    save: async function(vendor) {
      const loader = this.$loading.show({
        container: this.$refs.conditionContainer,
        canCancel: false
      })
      vendor.parent = this.user ? this.user.id : null
      try {
        const result = await this.$API.setting.updateVendor(vendor._id, vendor)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      } catch (e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    reload: function() {
      this.list = []
      for(const key in this.vendors) {
        const vendor = this.vendors[key]
        if (this.category !== '') {
          if (this.category === vendor.category.key) {
            this.list.push(vendor)
          }
        } else {
          this.list.push(vendor)
        }
      }
    }
  }
}
</script>

<template ref="conditionContainer">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive mb-0 rounded">
                        <table class="table align-middle table-nowrap">
                            <thead style="background-color: #343a40; color: #ffffff;">
                            <tr>
                                <th class="align-middle">한글명</th>
                                <th class="align-middle">영문명</th>
                                <th class="align-middle">
                                    <select class="form-select" v-model="category" @change="reload">
                                        <option value="">전체</option>
                                        <option value="casino">카지노</option>
                                        <option value="hotel">호텔카지노</option>
                                        <option value="slot">슬롯</option>
                                    </select>
                                </th>
                                <th class="align-middle">게임수</th>
                                <th class="align-middle">배팅상한가</th>
                                <th class="align-middle">로비사용</th>
                                <th class="align-middle">활성화</th>
                                <th class="align-middle" v-if="$store.getters['auth/user'].type === 'admin'">점검</th>
                                <th class="align-middle">저장</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="bg-soft" v-for="(vendor) in list" :key="vendor._id" :class="{'bg-secondary': !vendor.active, 'bg-danger': vendor.maintenance}">
                                <td>
                                    <input type="text" class="form-control" v-model="vendor.names.ko" :disabled="user || $store.getters['auth/user'].type !== 'admin'" />
                                </td>
                                <td>
                                    <input type="text" class="form-control" v-model="vendor.names.en" :disabled="user || $store.getters['auth/user'].type !== 'admin'" />
                                </td>
                                <td>
                                    {{ vendor.category.name }}
                                </td>
                                <td>
                                    {{ vendor.gameCount }}
                                </td>
                                <td class="text-end">
                                    <VueNumberFormat class="form-control text-end" v-model:value="vendor.maxBetAmount" :options="{ precision: 0, prefix: '', suffix: '', decimal: '.', thousand: ',', acceptNegative: false, isInteger: true  }"></VueNumberFormat>
                                </td>
                                <td>
                                    <div class="form-check form-check-success form-switch form-switch-lg d-inline-block">
                                        <input class="form-check-input" type="checkbox" :id="'useLobby' + vendor._id" v-model="vendor.useLobby" :disabled="user || $store.getters['auth/user'].type !== 'admin'">
                                        <label class="form-check-label" :for="'useLobby' + vendor._id"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="form-check form-check-success form-switch form-switch-lg d-inline-block">
                                            <input class="form-check-input" type="checkbox" :id="'active' + vendor._id" v-model="vendor.active">
                                            <label class="form-check-label" :for="'active' + vendor._id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td v-if="$store.getters['auth/user'].type === 'admin'">
                                    <div class="form-group">
                                        <div class="form-check form-check-danger form-switch form-switch-lg d-inline-block" dir="ltr">
                                            <input class="form-check-input" type="checkbox" :id="'maintenance' + vendor._id" v-model="vendor.maintenance">
                                            <label class="form-check-label" :for="'maintenance' + vendor._id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-primary btn-sm" @click="save(vendor)"><span class="fa fa-save"></span></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->

    </div>
</template>
