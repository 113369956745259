<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import Vendors from '../../components/setting/vendors'

export default {
  components: {
    Layout,
    PageHeader,
    Vendors
  },
  data() {
    return {
      title: this.$t('게임사') + this.$t('설정'),
    };
  },
  mounted() {
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <Vendors />

  </Layout>
</template>
